import Fuse from 'fuse.js';


export const ddragon_set_url = "http://ddragon.canisback.com/set3update/"
export const ddragon_image_url = ddragon_set_url + "champions/"
export const ddragon_image_items_url = ddragon_set_url + "items/"
export const ddragon_image_traits_url = ddragon_set_url + "traits_transparent/"

export const item_image = [{"id":1,"name":"B.F. Sword"},{"id":2,"name":"Recurve Bow"},{"id":3,"name":"Needlessly Large Rod"},{"id":4,"name":"Tear of the Goddess"},{"id":5,"name":"Chain Vest"},{"id":6,"name":"Negatron Cloak"},{"id":7,"name":"Giant's Belt"},{"id":8,"name":"Spatula"},{"id":9,"name":"Sparring Gloves"},{"id":11,"name":"Deathblade"},{"id":12,"name":"Giant Slayer"},{"id":13,"name":"Hextech Gunblade"},{"id":14,"name":"Spear of Shojin"},{"id":15,"name":"Guardian Angel"},{"id":16,"name":"Bloodthirster"},{"id":17,"name":"Zeke's Herald"},{"id":18,"name":"Blade of the Ruined King"},{"id":19,"name":"Infinity Edge"},{"id":22,"name":"Rapid Firecannon"},{"id":23,"name":"Guinsoo's Rageblade"},{"id":24,"name":"Statikk Shiv"},{"id":25,"name":"Titan's Resolve"},{"id":26,"name":"Runaan's Hurricane"},{"id":27,"name":"Zz'Rot Portal"},{"id":28,"name":"Infiltrator's Talons"},{"id":29,"name":"Last Whisper"},{"id":33,"name":"Rabadon's Deathcap"},{"id":34,"name":"Luden's Echo"},{"id":35,"name":"Locket of the Iron Solari"},{"id":36,"name":"Ionic Spark"},{"id":37,"name":"Morellonomicon"},{"id":38,"name":"Demolitionist's Charge"},{"id":39,"name":"Jeweled Gauntlet"},{"id":44,"name":"Seraph's Embrace"},{"id":45,"name":"Frozen Heart"},{"id":46,"name":"Chalice of Favor"},{"id":47,"name":"Redemption"},{"id":48,"name":"Star Guardian's Charm"},{"id":49,"name":"Hand Of Justice"},{"id":55,"name":"Bramble Vest"},{"id":56,"name":"Sword Breaker"},{"id":57,"name":"Red Buff"},{"id":58,"name":"Rebel Medal"},{"id":59,"name":"Shroud of Stillness"},{"id":66,"name":"Dragon's Claw"},{"id":67,"name":"Zephyr"},{"id":68,"name":"Celestial Orb"},{"id":69,"name":"Quicksilver"},{"id":77,"name":"Warmog's Armor"},{"id":78,"name":"Protector's Chestguard"},{"id":79,"name":"Trap Claw"},{"id":88,"name":"Force of Nature"},{"id":89,"name":"Dark Star's Heart"},{"id":99,"name":"Thief's Gloves"}]

export const item_to_trait = {
    18:"Blademaster",
    28:"Infiltrator",
    38:"Demolitionist",
    48:"Star Guardian",
    58:"Rebel",
    68:"Celestial",
    78:"Protector",
    89:"Dark Star"
}

export const fuse = new Fuse(item_image, {
  keys: ["name"],
  threshold:0.9
})


export function get_item_id(name){
    return fuse.search(name)[0].item.id
}

export function get_item_image(name){
    return ddragon_image_items_url + get_item_id(name).toString().padStart(2, "0") + ".png"
}

export const level_to_xp = {
    1:0,
    2:2,
    3:4,
    4:10,
    5:20,
    6:40,
    7:72,
    8:122,
    9:188
}