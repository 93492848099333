import React from 'react'

import '../css/DropReplay.css';

class DropReplay extends React.Component {
    
    constructor(props){
        super(props)
        this.state = {}
        
        this.dragCounter = 0
        
        this.handle_dragIn = this.handle_dragIn.bind(this)
        this.handle_dragOut = this.handle_dragOut.bind(this)
        this.handle_drag = this.handle_drag.bind(this)
        this.handle_dragDrop = this.handle_dragDrop.bind(this)
    }
    
    
    componentDidMount() {
        let div = document.querySelector('#root')
        div.addEventListener('dragenter', this.handle_dragIn)
        div.addEventListener('dragleave', this.handle_dragOut)
        div.addEventListener('dragover', this.handle_drag)
        div.addEventListener('drop', this.handle_dragDrop)
    }
    componentWillUnmount() {
        let div = document.querySelector('#root')
        div.removeEventListener('dragenter', this.handle_dragIn)
        div.removeEventListener('dragleave', this.handle_dragOut)
        div.removeEventListener('dragover', this.handle_drag)
        div.removeEventListener('drop', this.handle_dragDrop)
    }
    
    
    handle_dragIn(e){
        e.preventDefault()
        e.stopPropagation()
        this.dragCounter++
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            this.setState({drag: true})
        }
    }
    
    
    handle_dragOut(e){
        e.preventDefault()
        e.stopPropagation()
        this.dragCounter--
        if (this.dragCounter === 0) {
            this.setState({drag: false})
        }
    }
    
    
    handle_drag(e){
        e.preventDefault()
        e.stopPropagation()
    }
    
    
    handle_dragDrop(e){
        e.preventDefault()
        e.stopPropagation()
        this.setState({drag: false})
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            this.props.handleDrop(e.dataTransfer.files)
            e.dataTransfer.clearData()
            this.dragCounter = 0
        }
    }
    
    render(){
        return(
            <div className={(this.state.drag)?"drop-active":"drop"}><div className="drop-caption">Drop the replay</div></div>
        )
    }
    
}


export default DropReplay;