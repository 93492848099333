import React from 'react'

import '../css/Trait.css';

import {ddragon_image_traits_url} from './helpers'

class Trait extends React.Component{
    
    render(){
        return (
            <img 
                className={this.props.style + (this.props.tile?" tile-trait":"")} title={this.props.name + ": "+this.props.number} 
                src={ddragon_image_traits_url+this.props.name.trim().toLowerCase().replace('-','').replace(' ','')+".png"} 
                alt={this.props.name.trim().toLowerCase().replace('-','')} />
        )
    }
}


export default Trait;
