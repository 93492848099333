import React from 'react'

import Round from './Round';
import '../css/RoundList.css';


class RoundList extends React.Component{
	constructor(props) {
		super(props)
        this.state = {
            replay:this.props.replay,
            champion_cost:this.props.champion_cost,
            champion_traits:this.props.champion_traits,
            traits_style:this.props.traits_style
        }
    }
    
    componentDidUpdate(prevProps) {
        if(prevProps !== this.props)
            this.setState({
                replay:this.props.replay,
                champion_cost:this.props.champion_cost,
                champion_traits:this.props.champion_traits,
                traits_style:this.props.traits_style
            })
    }
    
    render(){
        return <div>
            {this.state.replay.map((round, i) =>
                <Round key={i} round={round} previous_round={this.state.replay[i-1]} 
                    champion_cost={this.state.champion_cost} 
                    champion_traits={this.state.champion_traits} 
                    traits_style={this.state.traits_style}/>   
            )}
        </div>
    }
}

export default RoundList;