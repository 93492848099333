import React from 'react'

import Items from './Items';
import '../css/Champions.css';

import {ddragon_image_url} from './helpers'



class Champion extends React.Component{
    
	constructor(props) {
		super(props)
        
        
        this.get_image = this.get_image.bind(this)
    }
    
    get_image(){
        return ddragon_image_url + this.props.champion.name.split("_")[1].toLowerCase() + ".png"
    }
    
    render(){
        if(this.props.champion == null)
            return <div></div>
        return (
            <div style={{display:'inline-block',border:(this.props.tile && this.props.carousel?"1px solid red":"")}}>
                <div className={"champion-border champion-border-" + this.props.champion.level + (this.props.tile?" tile-champion":"")}>
                    <Items item_1={this.props.champion.item_1} item_2={this.props.champion.item_2} item_3={this.props.champion.item_3} />
                    <img src={this.get_image()} alt={this.props.champion.name.split("_")[1].toLowerCase()}  className={"img-champion" + (this.props.tile?" tile-champion":"")} title={this.props.champion.name.split("_")[1]}/>
                </div>
            </div>
        )
    }
    
}

export default Champion;
