import React from 'react'

import '../css/Items.css';

import {get_item_image} from './helpers'


class Items extends React.Component{
    
    render(){
        return (
            <div className="items">
                {this.props.item_1 &&
                <img src={get_item_image(this.props.item_1)} alt={this.props.item_1} className="img-item"/>
                }
                {this.props.item_2 &&
                <img src={get_item_image(this.props.item_2)} alt={this.props.item_2} className="img-item"/>
                }
                {this.props.item_3 &&
                <img src={get_item_image(this.props.item_3)} alt={this.props.item_3} className="img-item"/>
                }
            </div>
        )
    }
}

export default Items;