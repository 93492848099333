import React from 'react'
import axios from 'axios'

import Shop from './Shop';
import RoundList from './RoundList';
import RoundTiles from './RoundTiles';
import Plots from './Plots';
import DropReplay from './DropReplay';
import '../css/Replay.css';

import {ddragon_set_url} from './helpers'

class Replay extends React.Component{
    
    constructor(props){
		super(props)
        this.state = {
            champion_traits:{},
            replay:this.props.replay,
            display:"default"
        }
        this.handle_drop = this.handle_drop.bind(this)
    }
    
    componentDidMount(){
        this.load_champions()
        this.load_traits()
    }
    
    load_champions(){
        let that = this
        axios.get(ddragon_set_url+'champions.json')
          .then(function (response) {
            // handle success
            let champion_traits = {}
            let champion_cost = {}
            for(var i in response.data){
                champion_traits[response.data[i]["championId"]] = response.data[i]["traits"]
                champion_cost[response.data[i]["championId"]] = response.data[i]["cost"]
            }
            console.log(champion_traits)
            console.log(champion_cost)
            that.setState({"champion_traits":champion_traits,"champion_cost":champion_cost})
          })
    }
    
    load_traits(){
        let that = this
        axios.get(ddragon_set_url+'traits.json')
          .then(function (response) {
            // handle success
            let traits_style = {}
            
            for(let trait of response.data){
                
                let set_style = {}
                for(let i = 1; i<= 15; i++)
                    set_style[i] = ""
                for(let set of trait.sets)
                    for(let j = set.min; j <= (set.max || 15); j++)
                        set_style[j] = set.style
                
                traits_style[trait.name] = set_style
            }
                
            console.log(traits_style)
            
            that.setState({"traits_style":traits_style})
          })
    }
    
    handle_drop(file){
        console.log(file)
        let reader = new FileReader();
        reader.readAsText(file[0], "UTF-8");
        let that = this
        reader.onload = function (evt) {
            that.setState({"replay":JSON.parse(evt.target.result)}, console.log(that.state))
        }
    }
    
    get_eog_rank(){
        return this.state.replay.data[this.state.replay.data.length-1].roster.rank
    }
    
    get_eog_level(){
        return this.state.replay.data[this.state.replay.data.length-1].xp.level
    }
    
    get_last_round(){
        return this.state.replay.data[this.state.replay.data.length-1].round_data.name
    }
    
    get_top_winstreak(){
        let top_winstreak = 0
        for(let r of this.state.replay.data)
            if(r.streak.outcome === "victory" && top_winstreak < r.streak.number)
                top_winstreak = r.streak.number
        return top_winstreak
    }
    
    get_top_lossstreak(){
        let top_lossstreak = 0
        for(let r of this.state.replay.data)
            if(r.streak.outcome !== "victory" && top_lossstreak < r.streak.number)
                top_lossstreak = r.streak.number
        return top_lossstreak
    }
    
    get_xp_ups(){
        let xp_ups = 0
        for(let r of this.state.replay.data)
            xp_ups += r.round_data.xp_up
        return xp_ups
    }
    
    get_rerolls(){
        let rerolls = 0
        for(let r of this.state.replay.data)
            rerolls += r.round_data.rerolls
        return rerolls
    }
    
    get_winrate(){
        let wins = 0
        let losses = 0
        for(let r of this.state.replay.data)
            if(r.round_data.type === "PVP")
                if(r.round_data.outcome === "victory")
                    wins += 1
                else
                    losses += 1
        return wins + "/" + losses
    }
       
	render(){
        return (
                <div className="post">
            
                    <div className="view-bar btn-group">
                        <button className={"btn btn-secondary " + (this.state.display === 'default' ? 'active' : '')} onClick={() => this.setState({display:"default"})}>
                            <i className="material-icons">view_list</i>
                        </button>
                        <button className={"btn btn-secondary " + (this.state.display === 'tiles' ? 'active' : '')} onClick={() => this.setState({display:"tiles"})}>
                            <i className="material-icons">view_module</i>
                        </button>
                        {<button className={"btn btn-secondary " + (this.state.display === 'plots' ? 'active' : '')} onClick={() => this.setState({display:"plots"})}>
                            <i className="material-icons">timeline</i>
                        </button>}
                        <button className={"btn btn-secondary " + (this.state.display === 'shop' ? 'active' : '')} onClick={() => this.setState({display:"shop"})}>
                            <i className="material-icons">shopping_basket</i>
                        </button>
                    </div>
                    
                    <DropReplay handleDrop={this.handle_drop}/>

                    {this.state.replay && <div className="top-info">
                        <div>
                            {this.state.replay.metadata.summoner_name}<br />
                            {new Date(this.state.replay.metadata.timestamp).toLocaleDateString()}<br />
                            {"Patch " + this.state.replay.metadata.version}
                        </div>
                         
                        <div>
                            EoG rank : {this.get_eog_rank()}<br />
                            EoG level : {this.get_eog_level()}<br />
                            Last round : {this.get_last_round()}<br />
                            Winrate : {this.get_winrate()}
                        </div>
                     
                        <div>
                            Top streak : <br />
                            <div className={"win_streak"}>
                                {this.get_top_winstreak()}
                            </div>
                            <div className={"loss_streak"}>
                                {this.get_top_lossstreak()}
                            </div>
                        </div>

                        <div>
                            <div className="top-rerolls">
                                {this.get_rerolls()}
                            </div>
                            <div className="top-xp-ups">
                                {this.get_xp_ups()}
                            </div>
                        </div>
                    </div>}

            
                    <hr />
                    {this.state.display === 'default' && <RoundList replay={this.state.replay.data} 
                        champion_cost={this.state.champion_cost} 
                        champion_traits={this.state.champion_traits} 
                        traits_style={this.state.traits_style}/>}
                        
                    {this.state.display === 'tiles' && <RoundTiles replay={this.state.replay.data} 
                     champion_cost={this.state.champion_cost} 
                        champion_traits={this.state.champion_traits} 
                        traits_style={this.state.traits_style}/>}
                        
                    {this.state.display === 'plots' && <Plots replay={this.state.replay.data}/>}
                    
                    {this.state.display === 'shop' && <Shop replay={this.state.replay.data} champion_cost={this.state.champion_cost} />}
                </div>
            )
    }
}


export default Replay;