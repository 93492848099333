import React from 'react'

import {LineChart, XAxis, YAxis, Tooltip, Legend, Line, CartesianGrid, ReferenceLine, ResponsiveContainer} from 'recharts'

import '../css/Plots.css';

import {level_to_xp} from './helpers'


class CustomTooltip extends React.Component{
    
    
    constructor(props){
		super(props)
    }
    
    render(){
        let current_data = this.props.data[this.props.label]
        if(current_data)
            return (
                <div className="custom-tooltip">
                    <p style={{'font':'bold'}}>Round {current_data.name}</p>
                    <div>Gold : {current_data.gold}</div>
                    <div>HP : {current_data.hp}</div>
                    <div>Level : {current_data.level}</div>
                    <div>Total XP : {current_data.xp}</div>
                    <div>Winrate : {current_data.w}/{current_data.l}</div>
                </div>
            )
        else
            return <div></div>
    }
    
}

class Plots extends React.Component{
    
    
    constructor(props){
		super(props)
        this.state = {
            replay:this.props.replay,
            data:[],
            data_by_round:{},
            show_gold:true,
            show_hp:true,
            show_xp:true,
            show_winrate:true
        }
        
        
        this.update_data = this.update_data.bind(this)
        
    }
    
    update_data(){
        
        let data = []
        let data_by_round = []
        let w = 0
        let l = 0
        
        for(let r of this.state.replay){
            
            if (r.round_data.type === "PVP")
                if(r.round_data.outcome === "victory")
                    w += 1
                else
                    l += 1
            
            let d = {}
            
            d["name"] = r.round_data.name
            d["hp"] = r.roster.hp
            d["xp"] = level_to_xp[r.xp.level] + parseInt(r.xp.current_xp)
            d["level"] = r.xp.level
            d["gold"] = parseInt(r.gold.gold)
            d["winrate"] =  String(((w/(w+l)) * 100).toPrecision(4))
            d["w"] = w
            d["l"] = l
            
            data.push(d)
            data_by_round[d["name"]] = d
            
        }
        
         this.setState({
             data:data,
             data_by_round:data_by_round
         })
    }
    
    
    
    componentDidMount() {
        this.update_data()
    }
    
    
    componentDidUpdate(prevProps) {
        if(prevProps !== this.props)
            this.setState({
                replay:this.props.replay
            }, () => this.update_data())
    }
    
    onLegendClick(event){
        
        console.log(event)
        
    }
    
    
    render(){
        console.log(this.state.data, this.state.data_by_round)
        return (
            <div className="row">
                <div className="col-md-11" style={{"height":"80vh","min-width":"800px","min-heeght":"500px"}}>
                    Plots
                    <ResponsiveContainer width="100%" height="100%">
                        <LineChart
                            data={this.state.data}
                        >
                            <XAxis dataKey="name"/>
                            <YAxis ticks={[50,100,150]} />
                            <Tooltip content={<CustomTooltip/>} data={this.state.data_by_round} />
                            <Legend onClick={this.onLegendClick}/>
                            <CartesianGrid strokeDasharray="3 3"/>

                            <ReferenceLine y={20} label="Level 5" stroke="#D8BFD8" strokeDasharray="3 3" />
                            <ReferenceLine y={40} label="Level 6" stroke="#D8BFD8" strokeDasharray="3 3" />
                            <ReferenceLine y={72} label="Level 7" stroke="#D8BFD8" strokeDasharray="3 3" />
                            <ReferenceLine y={122} label="Level 8" stroke="#D8BFD8" strokeDasharray="3 3" />

                            <ReferenceLine x={"2-4"} stroke="red" strokeDasharray="1" />
                            <ReferenceLine x={"3-4"} stroke="red" strokeDasharray="1" />
                            <ReferenceLine x={"4-4"} stroke="red" strokeDasharray="1" />
                            <ReferenceLine x={"5-4"} stroke="red" strokeDasharray="1" />
                            <ReferenceLine x={"6-4"} stroke="red" strokeDasharray="1" />
                            <ReferenceLine x={"7-4"} stroke="red" strokeDasharray="1" />
                            <ReferenceLine x={"8-4"} stroke="red" strokeDasharray="1" />

                            <ReferenceLine x={"2-1"} stroke="black" strokeDasharray="1" />
                            <ReferenceLine x={"3-1"} stroke="black" strokeDasharray="1" />
                            <ReferenceLine x={"4-1"} stroke="black" strokeDasharray="1" />
                            <ReferenceLine x={"5-1"} stroke="black" strokeDasharray="1" />
                            <ReferenceLine x={"6-1"} stroke="black" strokeDasharray="1" />
                            <ReferenceLine x={"7-1"} stroke="black" strokeDasharray="1" />
                            <ReferenceLine x={"8-1"} stroke="black" strokeDasharray="1" />

                            {this.state.show_gold && <Line type="monotone" name="Gold" dataKey="gold" stroke="#FFD700" animationDuration={500}/>}
                            {this.state.show_xp && <Line type="monotone" name="Total XP" dataKey="xp" stroke="#9400D3" animationDuration={500}/>}
                            {this.state.show_hp && <Line type="monotone" name="HP" dataKey="hp" stroke="#008000" animationDuration={500}/>}
                            {this.state.show_winrate && <Line type="monotone" name="Winrate" dataKey="winrate" stroke="#FF0000" animationDuration={500}/>}
                        </LineChart>
                    </ResponsiveContainer>
                </div>
                <div className="col-md-1">
                    <input checked={this.state.show_gold} type="checkbox" onChange={() => this.setState({ show_gold:!this.state.show_gold })} /> Gold<br />
                    <input checked={this.state.show_xp} type="checkbox" onChange={() => this.setState({ show_xp:!this.state.show_xp })} /> Total XP<br />
                    <input checked={this.state.show_hp} type="checkbox" onChange={() => this.setState({ show_hp:!this.state.show_hp })} /> HP<br />
                    <input checked={this.state.show_winrate} type="checkbox" onChange={() => this.setState({ show_winrate:!this.state.show_winrate })} /> Winrate<br />
                </div>
            </div>
        )
    }
    
}


export default Plots;