import React from 'react'

import Champion from './Champion';
import Trait from './Trait';
import '../css/Board.css';

import {get_item_id, item_to_trait} from './helpers'

class Board extends React.Component{
    
	constructor(props) {
		super(props)
        this.sort_traits = this.sort_traits.bind(this)
        this.get_all_items = this.get_all_items.bind(this)
    }
    
    get_all_items(){
        let items = []
        for(var i of Object.values(this.props.board)){
            if(i.item_1 !== "")
                items.push(get_item_id(i.item_1))
            if(i.item_2 !== "")
                items.push(get_item_id(i.item_2))
            if(i.item_3 !== "")
                items.push(get_item_id(i.item_3))
        }
        return items
    }
    
    
    get_champion_traits(){
        
        if(typeof this.props.traits_style == "undefined" || typeof this.props.champion_traits == "undefined")
            return
        
        let champions = []
        for(let cell in this.props.board){
            champions.push(this.props.board[cell]["name"])
        }
        
        champions = Array.from( new Set(champions))
        let all_traits = []
        for(let champion in champions){
            for(let t in this.props.champion_traits[champions[champion]]){
                all_traits.push(this.props.champion_traits[champions[champion]][t])
            }
        }
        
        let trait_count = {};
        for (let i of all_traits.values()) {
            trait_count[i] = 1 + (trait_count[i] || 0);
        }
        
        let board_items = this.get_all_items()
        for(let j of board_items)
            if(Object.keys(item_to_trait).indexOf(j.toString()) > 0)
                trait_count[item_to_trait[j]] = 1 + (trait_count[item_to_trait[j]] || 0)
        
        
		let traits_shown = Object.keys(trait_count).sort(this.sort_traits(trait_count)).filter(value => this.props.traits_style[value][trait_count[value]] !== "")
		return (
			<div>
				{traits_shown.map((value, index) => {
					if(traits_shown.length > 4 && index >= traits_shown.length/2 && index < (traits_shown.length/2+1))
						return [<br key={index+0.5} />,<Trait key={index} style={this.props.traits_style[value][trait_count[value]]} number={trait_count[value]}  name={value}/>]
					else
						return <Trait key={index} style={this.props.traits_style[value][trait_count[value]]} number={trait_count[value]}  name={value}/>
				})}
			</div>
		)
    }
    
    sort_traits(trait_count){
        
        let style_order = ["bronze", "silver", "gold", "chromatic"]
        let that = this
        return function(a, b) {
            if(style_order.indexOf(that.props.traits_style[a][trait_count[a]]) > style_order.indexOf(that.props.traits_style[b][trait_count[b]]))
                return -1
            else if(style_order.indexOf(that.props.traits_style[a][trait_count[a]]) < style_order.indexOf(that.props.traits_style[b][trait_count[b]]))
                return 1
            else if(style_order.indexOf(that.props.traits_style[a][trait_count[a]]) === style_order.indexOf(that.props.traits_style[b][trait_count[b]])){
                if(trait_count[a] > trait_count[b])
                    return -1
                else if(trait_count[a] < trait_count[b])
                    return 1
            }
            return 0;
        };
        
    }
    
    render(){
        return (
            <div className="board col-md-5">
                <div className="board_champions">
                    <div>Board :  </div>
                    <div>
                        {Object.entries(this.props.board).map(champion =>
                            <Champion key={champion[0]} champion={champion[1]} />
                        )}
                    </div>
                </div>

                <div className="board_traits pull-right">
                    {this.get_champion_traits()}
                </div>
                
            </div>
        )
    }
}


export default Board;