import React from 'react'

import Champion from './Champion';
import '../css/Bench.css';

class Bench extends React.Component{
    
    render(){
        return (
            <div className="bench col-md-3">
                <div>Bench :  </div>
                <div>
                {Object.entries(this.props.bench).map(champion =>
                    <Champion key={champion[0]} champion={champion[1]} />
                )}
                </div>
            </div>
        )
    }
}

export default Bench;