import React from 'react'

import Champion from './Champion';
import Board from './Board';
import Bench from './Bench';
import Carousel from './Carousel';
import CarouselPick from './CarouselPick';


import img_win from '../img/win.png';
import img_lose from '../img/lose.png';
import img_vs from '../img/vs.png';
import '../css/Round.css';

class Round extends React.Component{
	constructor(props) {
		super(props)
        this.state = {
            all_champion_level:3,
            champion_cost_filter:4,
            filtered_champion_level:2
        }
        
        
        this.get_champions = this.get_champions.bind(this)
        this.get_previous_champions = this.get_previous_champions.bind(this)
        /*
        this.updateCategory = this.updateCategory.bind(this)
        this.updateAuthor = this.updateAuthor.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.search = this.search.bind(this)
        this.triggerParamsChange = this.triggerParamsChange.bind(this)
        */
    }
    
    
    get_champions(){
        let round_champions = []
        for(let c of Object.values(this.props.round.board))
            round_champions.push(JSON.stringify({"name":c.name,"level":c.level}))
        for(let c of Object.values(this.props.round.bench))
            round_champions.push(JSON.stringify({"name":c.name,"level":c.level}))
        return round_champions
    }
    
    
    get_previous_champions(){
        let round_champions = []
        
        if(typeof this.props.previous_round != "undefined"){
            
            for(let c of Object.values(this.props.previous_round.board))
                round_champions.push(JSON.stringify({"name":c.name,"level":c.level}))
            
            for(let c of Object.values(this.props.previous_round.bench))
                round_champions.push(JSON.stringify({"name":c.name,"level":c.level}))
        }
        
        return round_champions
    }
    
    get_events_champions_level_up(){
        
        let event_champions = []
        
        let current_champions = this.get_champions()
        let previous_champions = this.get_previous_champions()
        
        
        if(typeof this.props.champion_cost != "undefined")
        // Get all level 3 champions, or level 2 champions for those having cost more than 3
        for(let c of current_champions){
            
            if(previous_champions.indexOf(c) === -1){
                
                let ch = JSON.parse(c)
                if((ch.level >= this.state.all_champion_level) || (ch.level >= this.state.filtered_champion_level && this.props.champion_cost[ch.name] >= this.state.champion_cost_filter)){
                    
                    event_champions.push(ch)
                    
                }
                
            }
            
        }
        return event_champions
        
    }
    
    get_events_rerolls(){
        if(this.props.round.gold.gold < 50)
            return this.props.round.round_data.rerolls
        return 0
    }
    
    get_event_level_ups(){
        if(this.props.round.gold.gold < 50)
            return this.props.round.round_data.xp_up
        return 0
    }
    
    
    render(){
        return (
            <div className="row round">
                <div className="col-md-2">
                    <div className="round_info_block stage">
                        {this.props.round.round_data.name}
                    </div>
                    <div className="round_info_block">
                        <div className="round-rerolls">
                            {this.props.round.round_data.rerolls}
                        </div>
                        <div className="round-xp-ups">
                            {this.props.round.round_data.xp_up}
                        </div>
                    </div>
                    <div className="round_info_block">
                        Lvl. {this.props.round.xp.level}<br />
                        XP {this.props.round.xp.current_xp}/{this.props.round.xp.xp_max}
                    </div>
                    <div className="round_info_block">
                        HP {this.props.round.roster.hp}<br />
                        Rank {this.props.round.roster.rank}/{this.props.round.roster.players}
                    </div>
                    <div className="round_info_block">
                        <div className="round-gold">
                            {this.props.round.gold.gold}
                        </div>
                        <div className={(this.props.round.streak.outcome==="victory")?"win_streak":"loss_streak"}>
                            {this.props.round.streak.number}
                        </div>
                    </div>

                    {this.props.round.round_data.outcome === "victory" && this.props.round.round_data.type === "PVP" && 
                        <div className="">
                            <img src={img_win} alt={"Win"}/><img src={img_vs} alt={"VS"}/>{" "+this.props.round.round_data.opponent}
                        </div>
                    }

                    {this.props.round.round_data.outcome !== "victory" && this.props.round.round_data.type === "PVP" && 
                        <div className="">
                            <img src={img_lose} alt={"Lose"}/><img src={img_vs} alt={"VS"}/>{" "+this.props.round.round_data.opponent}
                        </div>
                    }
                </div>

                {this.props.round.round_data.carousel !== null && 
                <div>
                    <Carousel carousel={this.props.round.round_data.carousel} />
                    <CarouselPick champion={this.props.round.round_data.carousel_pick} />
                </div>
                }

                {this.props.round.round_data.carousel == null &&
                <div>
                    <Board board={this.props.round.board} champion_traits={this.props.champion_traits} traits_style={this.props.traits_style} />
                    <Bench bench={this.props.round.bench} />
                </div>
                }
                <div className="col-md-1-5">
                    Events : <br />
                    {this.get_events_champions_level_up().map((champion, i) =>
                        <Champion key={i} champion={champion} />
                    )}
                    {this.get_events_rerolls() > 0 && <div className="reroll_event">{this.get_events_rerolls()}</div>}
                    {this.get_event_level_ups() > 0 && <div className="xp_up_event">{this.get_event_level_ups()}</div>}
                </div>
            </div>
        )
        
        
    }
}

export default Round;