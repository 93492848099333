import React from 'react'

import Champion from './Champion';
import '../css/Carousel.css';


class Carousel extends React.Component{
    
    render(){
        return (
            <div className="carousel col-md-5">
                <div>Champions available :  </div>
                <div>
                {Object.entries(this.props.carousel).map(champion =>
                    <Champion key={champion[0]} champion={champion[1]} />
                )}
                </div>
            </div>
        )
    }
}


export default Carousel;