import React from 'react';
import Champion from './Champion';
import '../css/Shop.css';


class Shop extends React.Component{
    
	constructor(props) {
		super(props)
        
        this.get_shops = this.get_shops.bind(this)
        
    }
    
    get_shops(){
        
        let shops_by_round = {}
        let champion_shops = {}
        
        for(let i of this.props.replay)
            shops_by_round[i.round_data.name] = i.store.champions_occurences
        
        for(let i in shops_by_round)
            for(let j in shops_by_round[i]){
                
                if(!(j in champion_shops))
                    champion_shops[j] = {}
                champion_shops[j][i] = parseInt(shops_by_round[i][j])
                
            }
        return champion_shops
        
    }
    
    get_row_border_class(champion, list_champions){
        
        if(this.props.champion_cost[champion] > 1)
            if(this.props.champion_cost[champion] > this.props.champion_cost[list_champions[list_champions.indexOf(champion) - 1]])
                return "shop-table-border-" + this.props.champion_cost[champion]
        
        return ""
        
    }
    
    get_column_border_class(round_data){
        
        if(round_data.type === "carousel")
            return "shop-table-border-carousel"
        
        else if(round_data.name.split("-")[1] === "1")
            return "shop-table-border-phase"
        
    }
    
    
    render(){
        let champion_shops = this.get_shops()
        if(typeof this.props.champion_cost == "undefined") return "Champion data not loaded?"
        let that = this
        let list_champions = Object.keys(this.props.champion_cost).sort(function(a,b){return that.props.champion_cost[a]-that.props.champion_cost[b]})
        
        return (
            <div>Cumulated appearances of the champion in the player shop. Here, by round 3-5, Caitlyn {!("TFT3_Caitlyn" in champion_shops) && "never appeared"}{"TFT3_Caitlyn" in champion_shops && <span>appeared {champion_shops["TFT3_Caitlyn"]["3-5"] || 0} times</span>} in the player shop (not counting carousels and PVE).
                
                <table className="table table-striped shop-table">
                    <thead>
                        <tr>
                            <th>
                            </th>

                            {this.props.replay.map((round, key) =>
                                <th key={key} className={this.get_column_border_class(round.round_data)}>{round.round_data.name}</th>
                            )}
                            
                            <th className="shop-table-border-total">
                                Total
                            </th>

                        </tr>
                    </thead>
                    <tbody>
                        {list_champions.map((champion, i) => 
                            <tr key={i} className={this.get_row_border_class(champion, list_champions)}>

                                <td className="shop-table-champion-header">
                                    <Champion champion={{"name":champion}} />
                                </td>

                                {that.props.replay.map((round, key) =>
                                    <td key={key} className={this.get_column_border_class(round.round_data)}>
                                        {  (champion in champion_shops) && (round.round_data.name === "1-1" 
                                                || champion_shops[champion][that.props.replay[key-1].round_data.name] === undefined 
                                                || champion_shops[champion][round.round_data.name] > champion_shops[champion][that.props.replay[key-1].round_data.name]
                                            )
                                            && champion_shops[champion][round.round_data.name]}
                                    </td>
                                )}
                                
                                <td className="shop-table-border-total">
                                    {typeof champion_shops[champion] != "undefined" && champion_shops[champion][that.props.replay[that.props.replay.length - 1].round_data.name]}
                                    {typeof champion_shops[champion] == "undefined" && 0}
                                </td>

                            </tr>
                        )}
                    </tbody>
                    
                </table>

            </div>
        )
    }
}


export default Shop;