import React from 'react'

import Champion from './Champion';
import '../css/CarouselPick.css';


class CarouselPick extends React.Component{
    
    render(){
        return (
            <div className="carousel_pick col-md-3">
                <div>Champion picked :  </div>
                <div>
                    <Champion champion={this.props.champion} />
                </div>
            </div>
        )
    }
}


export default CarouselPick;