import React from 'react'

import Trait from './Trait';
import Champion from './Champion';
import Round from './Round';

import img_win from '../img/win.png';
import img_lose from '../img/lose.png';

import {get_item_id, item_to_trait} from './helpers'

import '../css/Tile.css';


class Tile extends Round{
    
	constructor(props) {
		super(props)
        this.state = {
            all_champion_level:2,
            champion_cost_filter:4,
            filtered_champion_level:1
        }
    }
    
    sort_traits(trait_count){
        
        let style_order = ["bronze", "silver", "gold", "chromatic"]
        let that = this
        return function(a, b) {
            if(style_order.indexOf(that.props.traits_style[a][trait_count[a]]) > style_order.indexOf(that.props.traits_style[b][trait_count[b]]))
                return -1
            else if(style_order.indexOf(that.props.traits_style[a][trait_count[a]]) < style_order.indexOf(that.props.traits_style[b][trait_count[b]]))
                return 1
            else if(style_order.indexOf(that.props.traits_style[a][trait_count[a]]) === style_order.indexOf(that.props.traits_style[b][trait_count[b]])){
                if(trait_count[a] > trait_count[b])
                    return -1
                else if(trait_count[a] < trait_count[b])
                    return 1
            }
            return 0;
        };
        
    }
    
    get_all_items(board){
        let items = []
        for(var i of Object.values(board)){
            if(i.item_1 !== "")
                items.push(get_item_id(i.item_1))
            if(i.item_2 !== "")
                items.push(get_item_id(i.item_2))
            if(i.item_3 !== "")
                items.push(get_item_id(i.item_3))
        }
        return items
    }
    
    get_champion_traits(board){
        
        if(typeof this.props.traits_style == "undefined" || typeof this.props.champion_traits == "undefined")
            return
        
        let champions = []
        for(let cell in board){
            champions.push(board[cell]["name"])
        }
        
        champions = Array.from( new Set(champions))
        let all_traits = []
        for(let champion in champions){
            for(let t in this.props.champion_traits[champions[champion]]){
                all_traits.push(this.props.champion_traits[champions[champion]][t])
            }
        }
        
        let trait_count = {};
        for (let i of all_traits.values()) {
            trait_count[i] = 1 + (trait_count[i] || 0);
        }
        
        let board_items = this.get_all_items(board)
        for(let j of board_items)
            if(Object.keys(item_to_trait).indexOf(j.toString()) > 0)
                trait_count[item_to_trait[j]] = 1 + (trait_count[item_to_trait[j]] || 0)
        
        
		let traits_shown = Object.keys(trait_count).sort(this.sort_traits(trait_count)).filter(value => this.props.traits_style[value][trait_count[value]] !== "")
		return (
			<div>
				{traits_shown.map((value, index) => {
					if(traits_shown.length > 4 && index >= traits_shown.length/2 && index < (traits_shown.length/2+1))
						return [<br key={index-0.5} />,<Trait key={index} style={this.props.traits_style[value][trait_count[value]]} number={trait_count[value]}  name={value}/>]
					else
						return <Trait key={index} style={this.props.traits_style[value][trait_count[value]]} number={trait_count[value]}  name={value} tile/>
				})}
			</div>
		)
    }
    
    render(){
        return (
            <div className="">
                <div>
                    <div style={{"display":"inline-block"}}>
                        {this.props.round.round_data.name} &nbsp;
                        {this.props.round.round_data.outcome === "victory" && this.props.round.round_data.type === "PVP" && 
                            <img src={img_win} alt="win" />
                        }

                        {this.props.round.round_data.outcome !== "victory" && this.props.round.round_data.type === "PVP" && 
                            <img src={img_lose} alt="Lose"/>
                        }
                        <div style={{"display":"inline-block"}} className={(this.props.round.streak.outcome==="victory")?"win_streak":"loss_streak"}>
                            {this.props.round.streak.number}
                        </div>
                        <br />
                        <div style={{"display":"inline-block"}}>
                            Lvl. {this.props.round.xp.level} <br />
                            HP {this.props.round.roster.hp}
                        </div>
                        <div style={{"display":"inline-block"}}>
                            <div className="tile-gold">
                                {this.props.round.gold.gold}
                            </div>
                        </div>
                    </div>
                    <div style={{"display":"inline-block"}} className="pull-right">
                        {this.get_champion_traits(this.props.round.board)}
                    </div>
                </div>
                
            
                <div>
                    {this.props.round.round_data.carousel !== null && <Champion champion={this.props.round.round_data.carousel_pick} tile carousel/>}
                    {this.get_events_champions_level_up().map((champion, i) =>
                        <Champion key={i} champion={champion} tile/>
                    )}
                    {this.get_events_rerolls() > 0 && <div className="reroll_event reroll_event_tile">{this.get_events_rerolls()}</div>}
                    {this.get_event_level_ups() > 0 && <div className="xp_up_event xp_up_event_tile">{this.get_event_level_ups()}</div>}
                </div>
            </div>
        )
    }
}

export default Tile;
