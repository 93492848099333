import React from 'react'

import Tile from './Tile';
import RoundList from './RoundList';
import '../css/RoundTiles.css';

class RoundTiles extends RoundList{
    
    get_round_by_stage(){
        
        let round_by_stage = []
        
        for(let round of this.props.replay){
            
            let stage = parseInt(round.round_data.name.split("-")[0])
            
            if(!(stage in round_by_stage))
                round_by_stage[stage] = []
            
            round_by_stage[stage].push(round)
        }
        
        return round_by_stage
    }
    
    get_previous_round(stage, round, round_by_stage){
        
        if(stage === 1 && round === 0)
            return undefined
        
        if(round > 0)
            return round_by_stage[stage][round-1]
        else
            return round_by_stage[stage-1][round_by_stage[stage-1].length-1]
        
    }
    
    
    render(){
        let round_by_stage = this.get_round_by_stage()
        let that = this
        return (
            <div>Same as the round list, but just with the events, golds, hp and ranks
            
                {Object.keys(round_by_stage).map(stage => 
                    <div className="row" key={stage}>
                        {round_by_stage[stage].map((round, i) => 
                            <div key={i-0.5} className="col-md-1-5 tile">
                                <Tile key={i} round={round} previous_round={that.get_previous_round(parseInt(stage), i, round_by_stage)}
                                    champion_cost={this.state.champion_cost} 
                                    champion_traits={this.state.champion_traits} 
                                    traits_style={this.state.traits_style}/>
                            </div>
                        )}
                    </div>
                )}
            
            </div>
        )
    }
}
 export default RoundTiles;